<template>
  <div class="story-content-part story-content-part--default container" data-aos="fade-up" v-html="text" />
</template>

<script>
export default {
  name: 'StoryDescriptionTextPart',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
