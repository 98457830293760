<template>
  <div class="story-content">
    <StoryDescriptionTextPart
      v-for="(part, idx) in story.descriptionParts"
      :key="`${story.id}-${part.__typename}-${idx}`"
      :text="part.text"
    />

    <StoryCampaignActions
      class="container"
      :show-art-fair-attend-btn="showArtFairAttendBtn"
      :story="story"
      @request-art-fair="showArtFairAttendModal = true"
    />

    <div class="story-campaign-grid container">
      <ul class="story-campaign-grid__items">
        <li v-for="item in story.campaignGridItems" :key="item.id" class="story-campaign-grid__item" data-aos="fade-up">
          <StoryCampaignGridItem :item="item" :route-params="itemRouteParams" />
        </li>
      </ul>
    </div>

    <Transition name="slide">
      <LikeToAttend
        v-if="showArtFairAttendModal"
        :event="story.campaignArtFair"
        @close="showArtFairAttendModal = false"
        @requested="handleRequested"
      />
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import StoryDescriptionTextPart from '@/components/partials/StoryDescriptionTextPart';
import StoryCampaignActions from '@/components/partials/StoryCampaignActions';
import StoryCampaignGridItem from '@/components/partials/StoryCampaignGridItem';

export default {
  name: 'StoryCampaignGridTemplate',
  components: {
    StoryDescriptionTextPart,
    StoryCampaignActions,
    StoryCampaignGridItem,
    LikeToAttend: () => import('@/components/partials/LikeToAttend.vue'),
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      requestedArtFairAttend: false,
      showArtFairAttendModal: false,
    };
  },
  computed: {
    ...mapState(['museumRole']),
    itemRouteParams() {
      return { destination: this.story.destination ? this.story.destination.slug : null };
    },
    showArtFairAttendBtn() {
      return !this.museumRole && this.story.campaignArtFair && !this.requestedArtFairAttend;
    },
  },
  watch: {
    'story.campaignArtFair': {
      immediate: true,
      handler(campaignArtFair) {
        this.requestedArtFairAttend = !!campaignArtFair?.activeBooking;
      },
    },
  },
  methods: {
    handleRequested() {
      this.requestedArtFairAttend = true;
      this.showArtFairAttendModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-grid {
  margin-top: 70px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    margin-top: 90px;
    margin-bottom: 40px;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: 576px) {
      grid-row-gap: 60px;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.story-campaign-actions + .story-campaign-grid {
  @media (min-width: 576px) {
    margin-top: 115px;
  }
}
</style>
