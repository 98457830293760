<template>
  <div class="story-campaign-item">
    <AppRouteLink class="story-campaign-item__link" :route-to="routeTo">
      <figure class="story-campaign-item__container">
        <img v-if="media" class="story-campaign-item__image" :src="media" :alt="item.title" />
        <div v-else class="story-campaign-item__image" />
        <figcaption class="story-campaign-item__title">{{ item.title }}</figcaption>
      </figure>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import { makeRouteQueryFromData } from '@/helpers/routerHelper';
import { FILTER_TYPE_DESTINATIONS } from '@/helpers/data-filter/DataFilterTypes';

import AppRouteLink from '@/components/partials/AppRouteLink';

export default {
  name: 'StoryCampaignGridItem',
  components: { AppRouteLink },
  props: {
    item: {
      type: Object,
      required: true,
    },
    routeParams: {
      type: Object,
      default: () => ({ destination: null }),
    },
  },
  computed: {
    isPageEntity() {
      return !!this.item.linkedEntity.url;
    },
    media() {
      return this.item.media ? this.item.media.url : '';
    },
    routeTo() {
      const pathOrRouteData = this.isPageEntity ? this.item.linkedEntity.url : getEntityRoute(this.item.linkedEntity);
      if (!pathOrRouteData) {
        return;
      }

      let query = {};
      if (this.isPageEntity && this.routeParams.destination) {
        query = makeRouteQueryFromData({
          [FILTER_TYPE_DESTINATIONS]: [this.routeParams.destination],
        });
      }

      return typeof pathOrRouteData === 'object' ? { ...pathOrRouteData, query } : { path: pathOrRouteData, query };
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-item {
  $item: &;

  &__link {
    display: block;
    color: #363636;
  }

  &__container {
    margin: 0;

    @media (min-width: 768px) {
      height: 100%;
    }
  }

  &__image {
    max-width: 100%;
    width: 100%;
    height: 50vh;
    object-fit: cover;
    background-color: #d9d9d9;

    @media (min-width: 768px) {
      height: 220px;
    }
    @media (min-width: 992px) {
      height: 300px;
    }
    @media (min-width: 1200px) {
      height: 370px;
    }
  }

  &__title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.85px;
    line-height: 34px;
    text-transform: uppercase;
    transition: all 0.2s;

    @media (min-width: 576px) {
      margin-top: 20px;
      font-size: 22px;
    }
  }
  a#{$item}__link:hover &__title {
    opacity: 0.7;
  }
}
</style>
